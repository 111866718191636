import type { AxiosError, AxiosRequestConfig } from 'Axios'
import ToastEventBus from 'primevue/toasteventbus'
import { request } from './config'

class Api {
  errorHandler(error: AxiosError) {
    if (error) {
      const { data: responseData } = error?.response || {}
      const data: any = responseData

      if (data?.errors) {
        // Проверяем, является ли errors объектом с массивами ошибок
        if (typeof data.errors === 'object') {
          Object.values(data.errors).forEach((errorMessages: any) => {
            if (Array.isArray(errorMessages)) {
              errorMessages.forEach((message: string) => {
                ToastEventBus.emit('add', {
                  severity: 'error',
                  summary: 'Ошибка валидации',
                  detail: message,
                  life: 3000,
                })
              })
            }
          })
        } else if (Array.isArray(data.errors)) {
          // Если errors это массив
          data.errors.forEach((message: string) => {
            ToastEventBus.emit('add', {
              severity: 'error',
              summary: 'Ошибка',
              detail: message,
              life: 3000,
            })
          })
        }
      }
    }

    console.error(error)
  }

  async get(path: string, query: string = '', customConfig?: AxiosRequestConfig, plainResponse = false) {
    try {
      const response = await request.get(`${path}${query}`, customConfig)
      return plainResponse ? response : response.data
    } catch (err: any) {
      this.errorHandler(err as AxiosError)
      throw err?.response?.data
    }
  }

  async post(path: string, data?: any, body?: any, plainErr = false) {
    try {
      const postData = body ? data : JSON.stringify(data)
      const response = await request.post(path, postData, body)
      return response.data
    } catch (err: any) {
      this.errorHandler(err as AxiosError)
      if (plainErr) throw err?.response
      else throw err?.response?.data
    }
  }

  async delete(path: string, data?: any) {
    try {
      const response = await request.delete(path, {
        data: JSON.stringify(data),
      })
      return response.data
    } catch (err: any) {
      this.errorHandler(err as AxiosError)
      throw err?.response?.data
    }
  }

  async patch(path: string, data: any) {
    try {
      const response = await request.patch(path, JSON.stringify(data))
      return response.data
    } catch (err: any) {
      this.errorHandler(err as AxiosError)
      throw err?.response?.data
    }
  }

  async put(path: string, data: any) {
    try {
      const response = await request.put(path, JSON.stringify(data))
      return response.data
    } catch (err: any) {
      this.errorHandler(err as AxiosError)
      throw err?.response?.data
    }
  }
}

export default Api
