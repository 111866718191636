import type { RouteRecordRaw } from 'vue-router'

export interface RouteMeta {
  auth: boolean
  breadcrumbs: {
    icon: ReturnType<typeof defineAsyncComponent>
    title: string
  }
}
export const routes: readonly (RouteRecordRaw & { meta?: RouteMeta })[] = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
    component: () => import('@/views/Home/HomeView.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard/DashboardView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/dashboard.svg?component'),
            ),
            title: 'Дашбоард',
          },
        },
      },
      {
        path: 'advertising',
        name: 'Advertising',
        component: () => import('@/views/Advertising/AdvertisingView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/advertising.svg?component'),
            ),
            title: 'Рекламные кампании',
          },
        },
      },
      {
        path: 'mailing',
        name: 'Mailing',
        component: () => import('@/views/Mailing/MailingView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/mailing.svg?component'),
            ),
            title: 'Создать рассылку',
          },
        },
      },
      {
        path: 'templates',
        redirect: '/templates/sms',
        name: 'Templates',
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/templates.svg?component'),
            ),
            title: 'Шаблоны рассылок',
          },
        },
        children: [
          {
            path: ':type',
            name: 'TemplateList',
            component: () => import('@/views/Templates/TemplatesView.vue'),
            props: true,
          },
          {
            path: ':type/new',
            name: 'TemplateCreate',
            component: () => import('@/modules/Templates/components/TemplatesCreate.vue'),
            props: true,
            meta: {
              breadcrumbs: {
                title: 'Создание шаблона',
              },
            },
          },
          {
            path: ':type/:id',
            name: 'TemplateEdit',
            component: () => import('@/modules/Templates/components/TemplatesCreate.vue'),
            props: route => ({
              type: route.params.type,
              id: route.params.id,
              isEdit: true,
            }),
            meta: {
              breadcrumbs: {
                title: 'Изменение шаблона',
              },
            },
          },
        ],
      },
      {
        path: 'help',
        redirect: '/help/faq',
        name: 'Help',
        component: () => import('@/modules/Help/Help.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/help.svg?component'),
            ),
            title: 'Помощь',
          },
        },
        children: [
          {
            path: 'faq',
            name: 'HelpFaq',
            component: () => import('@/modules/Help/Faq/Faq.vue'),
            meta: {
              breadcrumbs: {
                title: 'Часто задаваемые вопросы',
              },
            },
          },
          {
            path: 'tickets',
            redirect: '/help/tickets/open',
            name: 'HelpTickets',
            component: () => import('@/modules/Help/Tickets/Tickets.vue'),
            meta: {
              breadcrumbs: {
                title: 'Обращения',
              },
            },
            children: [
              {
                path: 'open',
                name: 'HelpTicketsOpen',
                component: () => import('@/modules/Help/Tickets/components/OpenTickets.vue'),
                meta: {
                  breadcrumbs: {
                    title: 'Открытые обращения',
                  },
                },
              },
              {
                path: 'close',
                name: 'HelpTicketsClose',
                component: () => import('@/modules/Help/Tickets/components/CloseTickets.vue'),
                meta: {
                  breadcrumbs: {
                    title: 'Закрытые обращения',
                  },
                },
              },
              {
                path: ':id',
                name: 'HelpTicketView',
                component: () => import('@/modules/Help/Tickets/components/ViewTicket.vue'),
                meta: {
                  breadcrumbs: {
                    title: 'Просмотр обращения',
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'bases',
        name: 'Bases',
        redirect: '/bases/personal',
        component: () => import('@/views/Bases/BasesView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/dashboard.svg?component'),
            ),
            title: 'Базы рассылки',
          },
        },
        children: [
          {
            path: 'personal',
            name: 'PersonalBases',
            component: () => import('@/modules/Bases/components/PersonalBasesList.vue'),
            meta: {
              breadcrumbs: {
                title: 'Личные базы',
              },
            },
          },
          {
            path: 'target',
            name: 'TargetBases',
            component: () => import('@/modules/Bases/components/TargetedBasesList.vue'),
            meta: {
              breadcrumbs: {
                title: 'Таргет базы',
              },
            },
          },
          {
            path: 'opponent',
            name: 'OpponentBases',
            component: () => import('@/modules/Bases/components/OpponentBasesList.vue'),
            meta: {
              breadcrumbs: {
                title: 'Базы конкурентов',
              },
            },
          },
          {
            path: 'parsing',
            name: 'ParsingBases',
            component: () => import('@/modules/Bases/components/ParsingBasesList.vue'),
            meta: {
              breadcrumbs: {
                title: 'Базы Telegram',
              },
            },
          },
          {
            path: 'blacklist',
            name: 'BlacklistBases',
            component: () => import('@/modules/Bases/components/BlacklistBasesList.vue'),
            meta: {
              breadcrumbs: {
                title: 'Черный список',
              },
            },
          },
        ],
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/Profile/ProfileView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/profile.svg?component'),
            ),
            title: 'Профиль',
          },
        },
      },
      {
        path: 'tariffs',
        name: 'Tariffs',
        component: () => import('@/views/Tariffs/TariffsView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/profile.svg?component'),
            ),
            title: 'Тарифы',
          },
        },
      },
      {
        path: 'target',
        name: 'Target',
        component: () => import('@/views/Target/TargetView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/target.svg?component'),
            ),
            title: 'Таргет',
          },
        },
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('@/views/Notifications/NotificationsView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            title: 'Уведомления',
          },
        },
      },
      {
        path: 'landings',
        name: 'Landings',
        component: () => import('@/views/Landings/LandingsView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/landings.svg?component'),
            ),
            title: 'ТУРБО Страницы',
          },
        },
        children: [
          {
            path: ':token',
            name: 'LandingItemDetails',
            component: () =>
              import(
                '@/modules/Landings/components/TurboPages/LandingItemDetails.vue'
              ),
            props: true,
            meta: {
              breadcrumbs: {
                title: 'Информация о лендинге',
              },
            },
            children: [
              {
                path: 'edit',
                name: 'EditLanding',
                component: () =>
                  import(
                    '@/modules/Landings/components/TurboPages/LandingForm/Form.vue'
                  ),
                props: true,
                meta: {
                  breadcrumbs: {
                    title: 'Редактировать лендинг',
                  },
                },
              },
            ],
          },
          {
            path: 'new',
            name: 'NewLanding',
            component: () =>
              import(
                '@/modules/Landings/components/TurboPages/LandingForm/Form.vue'
              ),
            meta: {
              breadcrumbs: {
                title: 'Создать ТУРБО-страницу',
              },
            },
          },
        ],
      },
      {
        path: 'reports',
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/reports.svg?component'),
            ),
            title: 'Отчёты',
          },
        },
        children: [
          {
            path: '',
            name: 'Reports',
            component: () => import('@/views/Reports/ReportsView.vue'),
          },
          {
            path: ':id/:type?',
            name: 'Report-item',
            component: () => import('@/views/Reports/ReportView.vue'),
            meta: {
              auth: true,
              breadcrumbs: {
                title: 'Подробный отчёт',
              },
            },
          },
        ],
      },
      {
        path: '/names',
        name: 'Names',
        component: () => import('@/views/Names/NamesView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/names.svg?component'),
            ),
            title: 'Имена отправителей',
          },
        },
      },
      {
        path: '/documents',
        name: 'Documents',
        component: () => import('@/views/Documents/DocumentsView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/docs.svg?component'),
            ),
            title: 'Документы',
          },
        },
        children: [
          {
            path: 'fill',
            name: 'fill',
            component: () => import('@/modules/Documents/components/Balance/FillPage.vue'),
            props: true,
            meta: {
              auth: true,
              breadcrumbs: {
                title: 'Пополнение баланса',
              },
            },
          },
        ],
      },
      {
        path: '/partner',
        name: 'Partner',
        component: () => import('@/views/PartnerProgram/PartnerProgramView.vue'),
        meta: {
          auth: true,
          breadcrumbs: {
            icon: defineAsyncComponent(
              () => import('@/assets/img/icons/nav/partner.svg?component'),
            ),
            title: 'Партнерская программа',
          },
        },
      },
    ],
  },
  {
    path: '/auth',
    name: 'Auth',
    redirect: { name: 'Login' },
    children: [
      {
        path: 'login',
        name: 'Login',
        alias: 'withToken',
        component: () => import('@/modules/Auth/Login/Login.vue'),
        meta: {
          auth: false,
          breadcrumbs: {
            title: 'Авторизация',
          },
        },
      },
      {
        path: '/forgotten_password',
        name: 'ForgottenPassword',
        component: () =>
          import('@/modules/Auth/ForgottenPassword/ForgottenPassword.vue'),
        meta: {
          auth: false,
          breadcrumbs: {
            title: 'Восстановление пароля',
          },
        },
      },
      {
        path: 'register',
        name: 'Register',
        redirect: { name: 'RegisterTelegram' },
        children: [
          {
            path: 'phone',
            name: 'RegisterPhone',
            component: () => import('@/modules/Auth/Registration/Phone.vue'),
            meta: {
              auth: false,
              breadcrumbs: {
                title: 'Регистрация через телефон',
              },
            },
          },
          {
            path: 'email',
            name: 'RegisterEmail',
            component: () => import('@/modules/Auth/Registration/Email.vue'),
            meta: {
              auth: false,
              breadcrumbs: {
                title: 'Регистрация через Email',
              },
            },
          },
          {
            path: 'telegram',
            name: 'RegisterTelegram',
            component: () => import('@/modules/Auth/Registration/Telegram.vue'),
            meta: {
              auth: false,
              breadcrumbs: {
                title: 'Регистрация через Telegram',
              },
            },
          },
        ],
        component: () => import('@/views/Registration/RegistrationView.vue'),
      },
    ],
    component: () => import('@/views/Auth/AuthView.vue'),
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', redirect: { name: 'home' },
  },
]
