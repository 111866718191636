import type { Profile } from '@/types'

export function useReachGoal() {
  const project = inject('project') as ComputedRef<'zm' | 'sp' | 'sd'>
  const profile = useUser() as Ref<Profile>

  function checkYandexMetrikaInit() {
    if (typeof window.ym !== 'undefined') return true
    return false
  }

  const yandexMetrikaCodes = {
    registrationAndDemo: {
      zm: 33763184,
      sp: 61756621,
      sd: 46097091,
    },
    personalCabinet: {
      zm: 41232514,
      sp: 61756690,
      sd: 46472619,
    },
  }

  function getYandexMetrikaCode() {
    if (profile.value === null || profile.value.id === 47541) {
      return yandexMetrikaCodes.registrationAndDemo[project.value]
    } else return yandexMetrikaCodes.personalCabinet[project.value]
  }

  function reachYandexMetrikaGoal(name: string) {
    if (checkYandexMetrikaInit()) window.ym(getYandexMetrikaCode(), 'reachGoal', name)
  }

  function reachVkGoal() {
    const _tmr = window._tmr || (window._tmr = [])
    _tmr.push({ id: '3135668', type: 'reachGoal', goal: 'order' })
  }

  return { reachYandexMetrikaGoal, reachVkGoal }
}
