import * as yup from 'yup'
import 'yup-phone'

/**
 * Проверяет, является ли строка допустимым email.
 * @param {string} email - Строка для проверки.
 * @returns {boolean} - Возвращает true, если строка является допустимым email, иначе false.
 */
export function validateEmail(email: string) {
  return yup.string().email().isValidSync(email)
}

/**
 * Проверяет, является ли строка допустимым номером телефона в России.
 * @param {string} phone - Строка для проверки.
 * @returns {boolean} - Возвращает true, если строка является допустимым номером телефона, иначе false.
 */
export function validatePhone(phone: string) {
  return yup.string().phone('RU').isValidSync(phone)
}

/**
 * Проверяет, является ли строка допустимым URL.
 * @param {string} url - Строка для проверки.
 * @returns {boolean} - Возвращает true, если строка является допустимым URL, иначе false.
 */
export function validateUrl(url: string) {
  return yup
    .string()
    .test('isValidUrl', 'Неверный формат ссылки', (value) => {
      if (value) {
        if (value.includes(' ')) {
          return false
        }

        return /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.\S{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.\S{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.\S{2,}|www\.[a-zA-Z0-9]+\.\S{2,})$/.test(value)
      }
      return false
    })
    .isValidSync(url)
}
export default {}
