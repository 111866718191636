import userApi from '@/api/user'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useUserCRUDStore = defineStore('userCRUD', {
  state: () => ({
    promos: [],
  }),
  actions: {
    getPromos() {
      userApi.getPromos().then(({ data, success }) => {
        if (success) this.promos = data
      })
    },

    setBonusDontShowAgain({ type, id, onlyCount }: { type?: string, id: number | string, onlyCount: boolean }) {
      return userApi.setBonusDontShowAgain({
        id: id || type,
        onlyCount,
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserCRUDStore, import.meta.hot))
