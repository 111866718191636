<template>
  <Transition name="fade" mode="out-in">
    <AppLoadingCap v-if="route.path.includes('auth') ? false : !auth.ready() || userLoading" key="left" />
  </Transition>
  <RouterView />
  <Toast position="bottom-right" />
  <PopupRegForm />
  <LastNews />
  <CustomNews />
  <!-- div class="fixed bottom-0 right-0 flex gap-1">
    <span class="h-8 w-8 bg-[#82c198]" @click="handleUpdatePreset('zm')" />
    <span class="h-8 w-8 bg-[#3f8df6]" @click="handleUpdatePreset('sd')" />
    <span class="h-8 w-8 bg-[#df2e2e]" @click="handleUpdatePreset('sp')" />
  </div -->
  <di class="fixed bottom-0 right-0 flex gap-1 bg-black">
    <p>
      Высота: {{ height }}
    </p>
    <p>
      Ширина: {{ width }}
    </p>
  </di>
</template>

<script setup lang="ts">
import type { Profile } from '@/types'
import { useInitGTM } from '@/composables/useInitGTM'
import { useInitVkPixel } from '@/composables/useInitVkPixel'
import { useInitYandexMetrika } from '@/composables/useInitYandexMetrika'
import PopupRegForm from '@/modules/Auth/Registration/PopupForm.vue'
import { CustomNews, LastNews } from '@/modules/Popups'
import { useAuthCRUDStore } from '@/store/auth'
import { AppLoadingCap } from '@/UI'
import Toast from 'primevue/toast'
import { RouterView } from 'vue-router'

const route = useRoute()

const width = computed(() => {
  return window?.innerWidth
})
const height = computed(() => {
  return window?.innerHeight
})

const profile = useUser() as Ref<Profile>
const { initGTM } = useInitGTM()
const { initVkPixel } = useInitVkPixel()
const { initYandexMetrika } = useInitYandexMetrika()

// const updatePreset = inject('updatePreset') as (preset: string) => void
const auth = useAuth()
const { userLoading } = storeToRefs(useAuthCRUDStore())

// function handleUpdatePreset(preset: string) {
//   updatePreset(preset)
// }

watch(() => profile.value, (newProfileData) => {
  if (newProfileData) {
    if (profile.value.id === 47541) {
      initVkPixel()
      initYandexMetrika()
    } else initGTM()
  }
})
</script>
