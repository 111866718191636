import type { App } from 'vue'
import ToastEventBus from 'primevue/toasteventbus'

import { createAuth } from 'vue-auth3'

const request = axios.create({
  baseURL: import.meta.env.VITE_API,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})
function responseTracker(response: any) {
  const { success, data, message } = response
  const responseData = data || {}

  if (responseData.message || message) {
    const toastData = {
      severity: success ? 'info' : 'error',
      summary: success ? 'Успешно' : 'Ошибка',
      detail: responseData.message || message,
      life: 3000,
    }

    ToastEventBus.emit('add', toastData)
  }
}
request.interceptors.response.use(
  (response) => {
    responseTracker(response.data)

    // Проверка на success
    if (response?.data?.success === false) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ response })
    }

    return response
  },
  (error) => {
    const status = error.response?.status

    if (status === 401) {
      localStorage.removeItem('token')
      window.location.href = `${window.location.origin}/auth`
      return Promise.reject(error)
    }

    return Promise.reject(error)
  },
)
request.interceptors.request.use((request) => {
  if (request.headers.PartnerAuthorization) {
    request.headers.Authorization = request.headers.PartnerAuthorization
  } else if (!request.headers.Authorization) {
    request.headers.Authorization = `Bearer ${localStorage.getItem('token')?.replace(/"/g, '') || ''}`
  }
  return request
})

// eslint-disable-next-line import/no-mutable-exports
let auth = null
export default (app: App) => {
  auth = createAuth({
    plugins: {
      router: app.router,
    },
    drivers: {
      auth: {
        request(auth, options, token) {
          options.headers.Authorization = `Bearer ${token}`
          request.defaults.headers.common.Authorization = `Bearer ${token}`
          return options
        },
        response(auth, res) {
          const token = res.data.token
          if (token) {
            const i = token.split(/Bearer:?\s?/i)
            return i[i.length > 1 ? 1 : 0].trim()
          }
          return null
        },
      },
      http: {
        request,
      },
    },
    rolesKey: 'current_roles',
    authRedirect: { name: 'Login' },
    notFoundRedirect: { name: 'Dashboard' },
    tokenDefaultKey: 'token',
    userKey: 'profile',
    stores: ['storage'],
    fetchData: { url: 'profile/get', method: 'GET', enabled: true, keyUser: 'profile', cache: false },
    loginData: { redirect: '/dashboard', staySignedIn: true, fetchUser: false },
    refreshToken: { enabled: false },
  })

  app.use(auth)
}
export { auth, request }
